import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withRouter } from "react-router";
import PlaceholderText from "Components/PlaceholderText";

import { ENVIRONMENT_ID_FIELD } from "Constants/constants";
import { goToEnvironment, goToProject, goToOrganization } from "Libs/utils";

import Path from "./Path";

const Layout = styled.div`
  color: #98a0ab;
  width: 100%;
  img {
    transform: rotate(90deg);
  }
`;

const getEnvironmentPath = (
  environments,
  id,
  organizationId,
  projectId,
  push
) => {
  let environment = environments.find(env => env.id === id);
  let path = [];
  if (environment) {
    const environmentDescriptionId = environment[ENVIRONMENT_ID_FIELD];
    path.push(
      <span
        role="button"
        tabIndex="0"
        onClick={() =>
          goToEnvironment(
            push,
            organizationId,
            projectId,
            encodeURIComponent(environmentDescriptionId)
          )
        }
        onKeyUp={e =>
          e.keyCode === 13 &&
          goToEnvironment(
            push,
            organizationId,
            projectId,
            encodeURIComponent(environmentDescriptionId)
          )
        }
      >
        {environment.name}
      </span>
    );
  }

  while (environment && environment.parent) {
    environment = environments.find(env => env.id === environment.parent);
    if (environment) {
      const environmentDescriptionId = environment[ENVIRONMENT_ID_FIELD];
      path.unshift(
        <span
          role="button"
          tabIndex="0"
          onClick={() =>
            goToEnvironment(
              push,
              organizationId,
              projectId,
              encodeURIComponent(environmentDescriptionId)
            )
          }
          onKeyUp={e =>
            e.keyCode === 13 &&
            goToEnvironment(
              push,
              organizationId,
              projectId,
              encodeURIComponent(environmentDescriptionId)
            )
          }
        >
          {environment.name}
        </span>
      );
    }
  }

  return path;
};

class PathMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.openOrganization = this.openOrganization.bind(this);
    this.openProject = this.openProject.bind(this);
    this.openEnvironment = this.openEnvironment.bind(this);
    this.onProjectSelected = this.onProjectSelected.bind(this);
    this.onOrganizationSelected = this.onOrganizationSelected.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  openOrganization(e) {
    e.stopPropagation();
    this.setState(prevState => ({
      isOrganizationOpen: !prevState.isOrganizationOpen,
      isProjectOpen: false,
      isEnvironmentOpen: false
    }));
  }

  openProject(e) {
    e.stopPropagation();
    this.setState(prevState => ({
      isOrganizationOpen: false,
      isProjectOpen: !prevState.isProjectOpen,
      isEnvironmentOpen: false
    }));
  }

  openEnvironment(e) {
    e.stopPropagation();
    this.setState(prevState => ({
      isOrganizationOpen: false,
      isProjectOpen: false,
      isEnvironmentOpen: !prevState.isEnvironmentOpen
    }));
  }

  onClose(e) {
    e.stopPropagation();
    this.setState(() => ({
      isOrganizationOpen: false,
      isProjectOpen: false,
      isEnvironmentOpen: false
    }));
  }

  onProjectSelected(e, projectId) {
    e.stopPropagation();
    goToProject(this.props.push, this.props.organizationId, projectId);
  }

  onOrganizationSelected(e, organizationId) {
    e.stopPropagation();
    goToOrganization(this.props.push, organizationId);
  }

  onSearchChange(e) {
    this.setState({
      searchValue: e.target.value
    });
  }

  render() {
    const {
      environments,
      projects,
      organizationId,
      projectId,
      environmentId,
      push
    } = this.props;

    return (
      <Layout>
        <Path
          openOrganization={this.openOrganization}
          openProject={this.openProject}
          openEnvironment={() => {}}
          goToHome={() => push("/")}
          goToOrganization={
            organizationId && (() => goToOrganization(push, organizationId))
          }
          goToProject={
            projectId && (() => goToProject(push, organizationId, projectId))
          }
          organizationName={organizationId}
          projectName={
            (projects.find(p => p.id === projectId) || {}).title ? (
              (projects.find(p => p.id === projectId) || {}).title
            ) : (
              <PlaceholderText text="loading" />
            )
          }
          environmentPath={getEnvironmentPath(
            environments,
            environmentId,
            organizationId,
            projectId,
            this.props.push
          )}
          projectId={projectId}
          environmentId={environmentId}
          organizationId={organizationId}
        />
      </Layout>
    );
  }
}

PathMenu.propTypes = {
  environments: PropTypes.object,
  projects: PropTypes.object,
  projectId: PropTypes.string,
  environmentId: PropTypes.string,
  organizationId: PropTypes.string,
  push: PropTypes.func
};

export default withRouter(PathMenu);
