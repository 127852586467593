import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import OrganizationLogo from "Images/placeholders/organization-logo.svg";

const Layout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 32px;
  flex-shrink: 0;
  background: #fff;
  img {
    height: 40px;
    width: auto;
  }

  span + span {
    margin-left: 10px;
  }
`;

const Name = styled.span`
  font-size: 20px;
`;

const TeamNavBar = ({ teamTitle }) => (
  <Layout>
    <img src={OrganizationLogo} alt="Logo" />
    <Name>{teamTitle}</Name>
  </Layout>
);

TeamNavBar.propTypes = {
  teamTitle: PropTypes.string
};

export default TeamNavBar;
