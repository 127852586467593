import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { Map } from "immutable";
import Favicon from "react-favicon";
import { Link } from "react-router";

import { push as pushAction } from "Reducers/app";
import { loadEnvironment } from "Reducers/environment";

import NavBarLayout from "Components/nav_bar/NavBarLayout";
import NavBarLeftLayout from "Components/nav_bar/NavBarLeftLayout";
import NavBarRightLayout from "Components/nav_bar/NavBarRightLayout";
import NavBarLogo from "Components/nav_bar/NavBarLogo";
import PlatformIcon from "Icons/PlatformIcon";
import VendorIcon from "Icons/VendorIcon";
import NavBarLink from "Components/nav_bar/NavBarLink";
import UserWidget from "./UserWidget";
import UserMenu from "Containers/UserMenu";
import AnimatedHeaderWithArrow from "Components/AnimatedHeaderWithArrow";
import SimpleMenu from "Components/SimpleMenu";
import MaxWidthContainer from "Components/MaxWidthContainer";
import { getOrganizationDescriptionIdFromProject } from "Libs/utils";
import MegaMenu from "Containers/MegaMenu";
import HeaderAlert from "Components/HeaderAlert";
import PageHeader from "Components/PageHeader";
import Label from "Components/fields/Label";
import ActionDropdown from "Components/ActionDropdown";
import EnvironmentsTree from "Containers/EnvironmentsTree";
import ButtonLink from "Components/ButtonLink";
import SearchInput from "Components/SearchInput";
import BranchIcon from "Icons/BranchIcon";
import MergeIcon from "Icons/MergeIcon";
import SyncIcon from "Icons/SyncIcon";
import BackupIcon from "Icons/BackupIcon";
import ActionButton from "Components/ActionButton";

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: flex-start;
  & > button + button {
    margin-left: 8px;
  }
  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProjectOpen: false
    };

    this.toggleProjectMenu = this.toggleProjectMenu.bind(this);
    this.closeProjectMenu = this.closeProjectMenu.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, false);

    // If NavBar loads, make sure these are removed.
    const preLoadingContainer = document.getElementById("pre-loading");
    const fallbackContainer = document.getElementById("fallback");
    if (preLoadingContainer) {
      preLoadingContainer.parentNode.removeChild(preLoadingContainer);
    }

    if (fallbackContainer) {
      fallbackContainer.parentNode.removeChild(fallbackContainer);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.environment &&
      nextProps.environment.id &&
      (this.props.environmentId !== nextProps.environmentId ||
        (this.props.environment &&
          this.props.environment.id !== nextProps.environment.id))
    ) {
      this.props.loadEnvironment(
        nextProps.currentProjectOrganization,
        nextProps.environmentId
      );
    }
  }

  toggleTooltips(index) {
    this.setState(prevState => ({
      index: prevState.index === index ? -1 : index
    }));
  }

  toggleProjectMenu() {
    this.setState(prevState => ({
      isProjectOpen: !prevState.isProjectOpen
    }));
  }
  closeProjectMenu() {
    this.setState({
      isProjectOpen: false
    });
  }

  render() {
    const {
      projectId,
      organizationId,
      pushAction,
      push,
      projects,
      project,
      environmentId,
      environment,
      intl,
      onSearchChange,
      currentPathName,
      user
    } = this.props;
    const { /*isOrganizationOpen,*/ isProjectOpen } = this.state;

    let VendorFavicon;
    let VendorLogo;
    if (process.env.VENDOR_URL) {
      VendorFavicon = require("Vendor_resources/favicon.ico");
      VendorLogo = require("Vendor_resources/images/logo-ui.svg");
    }

    const envForUri = encodeURIComponent(environmentId);

    return (
      <NavBarLayout>
        <HeaderAlert />
        <PageHeader id="page-header" role="banner">
          <MaxWidthContainer>
            <div className="navigation-bar" role="navigation">
              <NavBarLeftLayout>
                <NavBarLogo>
                  {VendorFavicon && <Favicon url={VendorFavicon} />}
                  <Link className="logo" to={"/"}>
                    {VendorLogo ? (
                      <VendorIcon path={VendorLogo} size={30} />
                    ) : (
                      <PlatformIcon alt="icon" color="#ffffff" size={35} />
                    )}
                  </Link>
                </NavBarLogo>
                <MegaMenu
                  projectId={this.props.projectId}
                  environmentId={this.props.environmentId}
                  organizationId={this.props.organizationId}
                  push={this.props.push}
                />
              </NavBarLeftLayout>
              <NavBarRightLayout>
                {user &&
                  user.data && (
                    <UserMenu
                      accountUrl="https://accounts.platform.sh"
                      docsUrl="https://docs.platform.sh"
                      userInfo={user.data}
                      project={project}
                    />
                  )}
                <UserWidget
                  accountUrl="https://accounts.platform.sh"
                  source="ui"
                  nameOrAvatar={true}
                  getQueryString={() => ({
                    projectId
                  })}
                />
              </NavBarRightLayout>
            </div>
          </MaxWidthContainer>
        </PageHeader>
        <div className="float-box-wrapper">
          <MaxWidthContainer>
            <div className="float-box">
              {project ? (
                <React.Fragment>
                  <div className="navigation-menu">
                    <NavBarLink
                      role="menubar"
                      aria-label="Primary menu"
                      className={!environmentId ? "active" : ""}
                    >
                      <AnimatedHeaderWithArrow
                        prefixId="navbar-projects"
                        isOpen={isProjectOpen}
                        onHeaderClick={() =>
                          pushAction(
                            `/:organizationDescriptionId/${projectId}`,
                            {
                              organizationDescriptionId: getOrganizationDescriptionIdFromProject(
                                projects.get(projectId, {})
                              ),
                              projectId
                            }
                          )
                        }
                        goTo={`/${organizationId}/${projectId}`}
                        arrowClick={true}
                        onArrowClick={() => {
                          this.toggleProjectMenu();
                          setTimeout(() => {
                            document.getElementById("navbar-projects").focus();
                          }, 100);
                        }}
                        animate={false}
                        ariaLinkLabel="All projects"
                        ariaToggleLabel="Open projects"
                        className="navbar-link"
                        headerPadding="0 8px"
                        closeDropDown={this.closeProjectMenu}
                      >
                        <Label>
                          <FormattedMessage id="project" />
                        </Label>
                        <span className="text">
                          <span className="active" />
                          {project && project.title && project.title}
                        </span>
                      </AnimatedHeaderWithArrow>
                      <SimpleMenu
                        prefixId="navbar-projects"
                        className="navbar-dropdown"
                        isOpen={isProjectOpen}
                        onClick={(e, projectId) => {
                          e.stopPropagation();
                          pushAction(`/:organizationDescriptionId/:projectId`, {
                            organizationDescriptionId: getOrganizationDescriptionIdFromProject(
                              projects.get(projectId, {})
                            ),
                            projectId
                          });
                        }}
                        onKeyUp={e =>
                          e.keyCode === 27 &&
                          isProjectOpen &&
                          this.toggleProjectMenu()
                        }
                        width="200px"
                        objects={projects.valueSeq()}
                        toggleMenu={this.toggleProjectMenu}
                        aria-label="Projects"
                        project={projectId}
                        organization={getOrganizationDescriptionIdFromProject(
                          projects.get(projectId, {})
                        )}
                      />
                    </NavBarLink>
                    <ActionDropdown
                      label={
                        environment && environment.title
                          ? environment.title
                          : "Select environment"
                      }
                      extraLabel="Environment"
                      className={`environments-select${
                        environmentId ? " active" : ""
                      }`}
                      inline={true}
                      id="environment-menu"
                      openFuction={setTimeout(() => {
                        document.getElementById("environment-search").focus();
                      }, 100)}
                    >
                      <EnvironmentsTree
                        projectId={projectId}
                        organizationId={organizationId}
                        layout="menu"
                        environmentName={environmentId}
                      />
                    </ActionDropdown>
                  </div>
                  {environment &&
                    typeof environment.hasLink === "function" && (
                      <ActionButtons
                        className={`action-buttons${
                          environment && environment.status === "inactive"
                            ? " inactive"
                            : ""
                        }`}
                      >
                        {currentPathName ===
                          `/${organizationId}/${projectId}/${envForUri}` && (
                          <React.Fragment>
                            <ActionButton
                              id="environment-branch-btn"
                              onClick={
                                environment.hasLink("#branch")
                                  ? e => {
                                      e.stopPropagation();
                                      document.activeElement.blur();
                                      push(
                                        `/${organizationId}/${projectId}/${envForUri}/actions/branch`
                                      );
                                    }
                                  : () => {}
                              }
                              className={`${!environment.hasLink("#branch") &&
                                "disabled"}`}
                              tabIndex="0"
                              type="button"
                              aria-label={intl.formatMessage({ id: "branch" })}
                            >
                              <BranchIcon />{" "}
                              <span>
                                {intl.formatMessage({ id: "branch" })}
                              </span>
                            </ActionButton>
                            <ActionButton
                              id="environment-merge-btn"
                              onClick={
                                environment.hasLink("#merge")
                                  ? e => {
                                      e.preventDefault();
                                      document.activeElement.blur();
                                      push(
                                        `/${organizationId}/${projectId}/${envForUri}/actions/merge`
                                      );
                                    }
                                  : () => {}
                              }
                              className={`${!environment.hasLink("#merge") &&
                                "disabled"}`}
                              tabIndex="0"
                              href="#"
                              type="button"
                              aria-label={intl.formatMessage({ id: "merge" })}
                            >
                              <MergeIcon />{" "}
                              <span>{intl.formatMessage({ id: "merge" })}</span>
                            </ActionButton>
                            <ActionButton
                              id="environment-synchronize-btn"
                              onClick={
                                environment.hasLink("#synchronize")
                                  ? e => {
                                      e.preventDefault();
                                      document.activeElement.blur();
                                      push(
                                        `/${organizationId}/${projectId}/${envForUri}/actions/sync`
                                      );
                                    }
                                  : () => {}
                              }
                              className={`${!environment.hasLink(
                                "#synchronize"
                              ) && "disabled"}`}
                              tabIndex="0"
                              href="#"
                              type="button"
                              aria-label={intl.formatMessage({ id: "sync" })}
                            >
                              <SyncIcon />{" "}
                              <span>{intl.formatMessage({ id: "sync" })}</span>
                            </ActionButton>
                          </React.Fragment>
                        )}
                        {(currentPathName ===
                          `/${organizationId}/${projectId}/${envForUri}` ||
                          currentPathName ===
                            `/${organizationId}/${projectId}/${envForUri}/backups`) && (
                          <ActionButton
                            id="environment-backup-btn"
                            onClick={
                              environment.hasLink("#backup")
                                ? e => {
                                    e.preventDefault();
                                    document.activeElement.blur();
                                    push(
                                      `/${organizationId}/${projectId}/${envForUri}/actions/backup`
                                    );
                                  }
                                : () => {}
                            }
                            className={`${!environment.hasLink("#backup") &&
                              "disabled"}`}
                            tabIndex="0"
                            href="#"
                            type="button"
                            aria-label={intl.formatMessage({ id: "backup" })}
                          >
                            <BackupIcon />{" "}
                            <span>{intl.formatMessage({ id: "backup" })}</span>
                          </ActionButton>
                        )}
                      </ActionButtons>
                    )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {!currentPathName.includes(`/${organizationId}/settings`) &&
                  !currentPathName.includes(
                    `/${organizationId}/billing/details`
                  ) ? (
                    <SearchInput
                      id="project-search-input"
                      placeholder={
                        currentPathName.includes("billing/history")
                          ? "Search orders"
                          : intl.formatMessage({
                              id: "dashboard.search_placeholder"
                            })
                      }
                      onChange={onSearchChange}
                      iconSize={18}
                      className="float"
                    />
                  ) : (
                    <div />
                  )}
                  <ButtonLink
                    id="add-project"
                    className="add-project"
                    addLink={true}
                    to={`/${
                      organizationId ? organizationId : "projects"
                    }/create-project`}
                    text={intl.formatMessage({ id: "add_project" })}
                  />
                </React.Fragment>
              )}
            </div>
          </MaxWidthContainer>
        </div>
      </NavBarLayout>
    );
  }
}

const mapStateToProps = (state, props) => ({
  projectId: state.project
    ? state.project.getIn(["data", props.organizationId, props.projectId], {})
        .id
    : "",
  projects: state.project
    .getIn(["data"], new Map())
    .keySeq()
    .reduce((projects, key) => {
      state.project.getIn(["data", key], new Map()).forEach(project => {
        projects = projects.mergeDeep(projects.set(project.id, project));
      });
      return projects;
    }, new Map()),
  project: state.project
    ? state.project.getIn(["data", props.organizationId, props.projectId], {})
        .data
    : "",
  environment:
    state.environment &&
    state.environment.getIn(
      ["data", props.organizationId, props.projectId, props.environmentId],
      {}
    ),
  environments: state.environment
    ? state.environment.getIn(
        ["data", props.organizationId, props.projectId],
        new Map()
      )
    : {}
});

const mapDispatchToProps = (dispatch, props) => ({
  pushAction: (path, args) => dispatch(pushAction(path, args)),
  loadEnvironment: (
    currentProjectOrganization = props.organizationId,
    currentEnvironmentId = props.environmentId
  ) =>
    dispatch(
      loadEnvironment(
        currentEnvironmentId,
        props.projectId,
        currentProjectOrganization
      )
    )
});

NavBar.propTypes = {
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  environmentId: PropTypes.string,
  environments: PropTypes.object,
  push: PropTypes.func,
  project: PropTypes.object,
  environment: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pushAction: PropTypes.func,
  projects: PropTypes.object,
  intl: PropTypes.object,
  onSearchChange: PropTypes.func,
  loadEnvironment: PropTypes.func,
  currentPathName: PropTypes.string,
  user: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(NavBar));
