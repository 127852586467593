import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import styled from "styled-components";

import Button from "Components/Button";
import FullPageError from "Components/FullPageError";

const Layout = styled.div`
  z-index: 10;
  position: relative;
`;

class EnvironmentError extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }
  render() {
    const {
      environment,
      error,
      isLoading,
      intl,
      projectId,
      organizationId
    } = this.props;

    if (environment || isLoading || !error) {
      return false;
    }
    const errorHasMessage = error && error._root && error._root.entries;
    let errorText = {};
    if (errorHasMessage) {
      error._root.entries.map(function(key) {
        errorText[key[0]] = key[1];
      });
    }
    let errorTitle = intl.formatMessage({ id: "error_loading_environment" });
    let errorMessage = intl.formatMessage({
      id: "error_loading_environment_description"
    });

    if (errorText.code === 403) {
      errorTitle = intl.formatMessage({ id: "error_403_title" });
      errorMessage = intl.formatMessage({ id: "error_environment_403" });
    }

    return (
      <Layout>
        <FullPageError
          blueTitle="Oops,"
          title={errorTitle}
          message={errorMessage}
        >
          <div className="actions">
            <Button
              className="primary"
              onClick={() =>
                (window.location = `/${organizationId}/${projectId}`)
              }
            >
              Return to project
            </Button>
          </div>
        </FullPageError>
      </Layout>
    );
  }
}

EnvironmentError.propTypes = {
  environment: PropTypes.object,
  intl: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  projectId: PropTypes.string,
  organizationId: PropTypes.string
};

const mapStateToProps = (state, props) => ({
  environment:
    state.environment &&
    state.environment.getIn([
      "data",
      props.organizationId,
      props.projectId,
      props.environmentId
    ]),
  error: state.environment && state.environment.get("environmentLoadingError"),
  isLoading: state.environment && state.environment.get("loading")
});

export default connect(mapStateToProps)(injectIntl(EnvironmentError));
