import React from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import PropTypes from "prop-types";
import styled from "styled-components";

import { loadEnvironments } from "Reducers/environment";

import PathMenu from "Components/PathMenu";

const Layout = styled.div`
  width: calc(100% - 28px);
  box-sizing: border-box;
  padding: 0 0 0 16px;
  @media (min-width: 768px) {
    padding: 0 0 0 24px;
  }
`;

class MegaMenu extends React.Component {
  componentDidMount() {
    this.props.loadProjects();

    if (this.props.projectId && this.props.organizationId) {
      this.props.loadEnvironments(
        this.props.projectId,
        this.props.organizationId
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.projectId &&
      nextProps.projectId !== this.props.projectId &&
      nextProps.organizationId !== this.props.organizationId
    ) {
      this.props.loadEnvironments(
        nextProps.projectId,
        nextProps.organizationId
      );
    }
  }

  render() {
    const {
      environments,
      projects,
      push,
      projectId,
      environmentId,
      organizationId
    } = this.props;

    return (
      <Layout>
        <PathMenu
          environments={environments.valueSeq()}
          projects={projects.valueSeq()}
          projectId={projectId}
          environmentId={environmentId}
          organizationId={organizationId}
          push={push}
        />
      </Layout>
    );
  }
}

MegaMenu.propTypes = {
  environments: PropTypes.object,
  projects: PropTypes.object,
  push: PropTypes.func,
  loadEnvironments: PropTypes.func,
  loadProjects: PropTypes.func,
  projectId: PropTypes.string,
  organizationId: PropTypes.string,
  environmentId: PropTypes.string,
  currentProjectOrganization: PropTypes.string
};

const mapStateToProps = (state, props) => {
  const environment = state.environment || new Map();
  const project = state.project || new Map();

  return {
    environments: environment.getIn(
      ["data", props.organizationId, props.projectId],
      new Map()
    ),
    projects: project.getIn(["data", props.organizationId], new Map()),
    isLoading: environment.get("loading")
  };
};

const mapDispatchToProps = dispatch => ({
  loadEnvironments: (projectId, organizationId) =>
    dispatch(loadEnvironments(projectId, organizationId)),
  loadProjects: () => {
    import("Reducers/project").then(projectReducer =>
      dispatch(projectReducer.loadProjects())
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MegaMenu);
