import styled from "styled-components";

const calcColumn = (columnSize, totalColumns) => {
  return columnSize * totalColumns + 32 * (totalColumns - 1);
};
export default styled.div`
  width: 100%;
  margin: 0 auto;
  @media (min-width: 1024px) {
    max-width: 944px;
    width: 944px;
  }
  @media (min-width: 1280px) {
    max-width: 1204px;
    width: 1204px;
  }
  @media (min-width: 1440px) {
    max-width: 1360px;
    width: 1360px;
  }
  @media (min-width: 1920px) {
    max-width: 1680px;
    width: 1680px;
  }
  // Only use columns for areas that should be a fixed width.
  // The main area should have a fluid width.
  .col-1 {
    @media (min-width: 1024px) {
      width: 49px;
      min-width: 49px;
    }
    @media (min-width: 1280px) {
      width: 71px;
      min-width: 71px;
    }
    @media (min-width: 1440px) {
      width: 84px;
      min-width: 84px;
    }
    @media (min-width: 1920px) {
      width: 110px;
      min-width: 110px;
    }
  }
  .col-2 {
    @media (min-width: 1024px) {
      width: ${calcColumn(49, 2)}px;
      min-width: ${calcColumn(49, 2)}px;
    }
    @media (min-width: 1280px) {
      width: ${calcColumn(71, 2)}px;
      min-width: ${calcColumn(71, 2)}px;
    }
    @media (min-width: 1440px) {
      width: ${calcColumn(84, 2)}px;
      min-width: ${calcColumn(84, 2)}px;
    }
    @media (min-width: 1920px) {
      width: ${calcColumn(110, 2)}px;
      min-width: ${calcColumn(110, 2)}px;
    }
  }
  .col-3 {
    @media (min-width: 1024px) {
      width: ${calcColumn(49, 3)}px;
      min-width: ${calcColumn(49, 3)}px;
    }
    @media (min-width: 1280px) {
      width: ${calcColumn(71, 3)}px;
      min-width: ${calcColumn(71, 3)}px;
    }
    @media (min-width: 1440px) {
      width: ${calcColumn(84, 3)}px;
      min-width: ${calcColumn(84, 3)}px;
    }
    @media (min-width: 1920px) {
      width: ${calcColumn(110, 3)}px;
      min-width: ${calcColumn(110, 3)}px;
    }
  }
  .col-4 {
    @media (min-width: 1024px) {
      width: ${calcColumn(49, 4)}px;
      min-width: ${calcColumn(49, 4)}px;
    }
    @media (min-width: 1280px) {
      width: ${calcColumn(71, 4)}px;
      min-width: ${calcColumn(71, 4)}px;
    }
    @media (min-width: 1440px) {
      width: ${calcColumn(84, 4)}px;
      min-width: ${calcColumn(84, 4)}px;
    }
    @media (min-width: 1920px) {
      width: ${calcColumn(110, 4)}px;
      min-width: ${calcColumn(110, 4)}px;
    }
  }
  .col-5 {
    @media (min-width: 1024px) {
      width: ${calcColumn(49, 5)}px;
      min-width: ${calcColumn(49, 5)}px;
    }
    @media (min-width: 1280px) {
      width: ${calcColumn(71, 5)}px;
      min-width: ${calcColumn(71, 5)}px;
    }
    @media (min-width: 1440px) {
      width: ${calcColumn(84, 5)}px;
      min-width: ${calcColumn(84, 5)}px;
    }
    @media (min-width: 1920px) {
      width: ${calcColumn(110, 5)}px;
      min-width: ${calcColumn(110, 5)}px;
    }
  }
  .col-6 {
    @media (min-width: 1024px) {
      width: ${calcColumn(49, 6)}px;
      min-width: ${calcColumn(49, 6)}px;
    }
    @media (min-width: 1280px) {
      width: ${calcColumn(71, 6)}px;
      min-width: ${calcColumn(71, 6)}px;
    }
    @media (min-width: 1440px) {
      width: ${calcColumn(84, 6)}px;
      min-width: ${calcColumn(84, 6)}px;
    }
    @media (min-width: 1920px) {
      width: ${calcColumn(110, 6)}px;
      min-width: ${calcColumn(110, 6)}px;
    }
  }
  .col-7 {
    @media (min-width: 1024px) {
      width: ${calcColumn(49, 7)}px;
      min-width: ${calcColumn(49, 7)}px;
    }
    @media (min-width: 1280px) {
      width: ${calcColumn(71, 7)}px;
      min-width: ${calcColumn(71, 7)}px;
    }
    @media (min-width: 1440px) {
      width: ${calcColumn(84, 7)}px;
      min-width: ${calcColumn(84, 7)}px;
    }
    @media (min-width: 1920px) {
      width: ${calcColumn(110, 7)}px;
      min-width: ${calcColumn(110, 7)}px;
    }
  }
  .col-8 {
    @media (min-width: 1024px) {
      width: ${calcColumn(49, 8)}px;
      min-width: ${calcColumn(49, 8)}px;
    }
    @media (min-width: 1280px) {
      width: ${calcColumn(71, 8)}px;
      min-width: ${calcColumn(71, 8)}px;
    }
    @media (min-width: 1440px) {
      width: ${calcColumn(84, 8)}px;
      min-width: ${calcColumn(84, 8)}px;
    }
    @media (min-width: 1920px) {
      width: ${calcColumn(110, 8)}px;
      min-width: ${calcColumn(110, 8)}px;
    }
  }
  .col-9 {
    @media (min-width: 1024px) {
      width: ${calcColumn(49, 9)}px;
      min-width: ${calcColumn(49, 9)}px;
    }
    @media (min-width: 1280px) {
      width: ${calcColumn(71, 9)}px;
      min-width: ${calcColumn(71, 9)}px;
    }
    @media (min-width: 1440px) {
      width: ${calcColumn(84, 9)}px;
      min-width: ${calcColumn(84, 9)}px;
    }
    @media (min-width: 1920px) {
      width: ${calcColumn(110, 9)}px;
      min-width: ${calcColumn(110, 9)}px;
    }
  }
  .col-10 {
    @media (min-width: 1024px) {
      width: ${calcColumn(49, 10)}px;
      min-width: ${calcColumn(49, 10)}px;
    }
    @media (min-width: 1280px) {
      width: ${calcColumn(71, 10)}px;
      min-width: ${calcColumn(71, 10)}px;
    }
    @media (min-width: 1440px) {
      width: ${calcColumn(84, 10)}px;
      min-width: ${calcColumn(84, 10)}px;
    }
    @media (min-width: 1920px) {
      width: ${calcColumn(110, 10)}px;
      min-width: ${calcColumn(110, 10)}px;
    }
  }
  .col-11 {
    @media (min-width: 1024px) {
      width: ${calcColumn(49, 11)}px;
      min-width: ${calcColumn(49, 11)}px;
    }
    @media (min-width: 1280px) {
      width: ${calcColumn(71, 11)}px;
      min-width: ${calcColumn(71, 11)}px;
    }
    @media (min-width: 1440px) {
      width: ${calcColumn(84, 11)}px;
      min-width: ${calcColumn(84, 11)}px;
    }
    @media (min-width: 1920px) {
      width: ${calcColumn(110, 11)}px;
      min-width: ${calcColumn(110, 11)}px;
    }
  }
  .col-12 {
    width: 100%;
    margin: 0 auto;
    @media (min-width: 1024px) {
      max-width: 944px;
      width: 944px;
    }
    @media (min-width: 1280px) {
      max-width: 1204px;
      width: 1204px;
    }
    @media (min-width: 1440px) {
      max-width: 1360px;
      width: 1360px;
    }
    @media (min-width: 1920px) {
      max-width: 1680px;
      width: 1680px;
    }
  }
`;
