import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import Heading1 from "Components/styleguide/Heading1";
import sadMoonMan from "Images/sad-moon-man.svg";
import stars from "Images/stars.svg";
import PlatformWordmark from "Components/icons/PlatformWordmark";
import { Link } from "react-router";

const ErrorMessagePageWrapper = styled.div`
  background: #1a192b;
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10;
  .header-wrapper {
    margin: 5vw 10vw 0 10vw;
    svg {
      height: 38px;
      width: auto;
    }
  }
  h1 {
    margin-bottom: 10px;
    color: #fff;
    .blue {
      color: ${props => props.theme.brandColor};
    }
  }
  p {
    font-size: 15px;
  }
  a.button {
    background-color: rgb(22, 100, 255);
    color: rgb(255, 255, 255);
  }
  .moon-man {
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    right: 0;
    bottom: 0;
    width: 100%;
  }
  .moon-man img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  .text-wrapper {
    width: 80vw;
    margin: 10vw 10vw 0 10vw;
    max-height: 60vh;
    z-index: 2;
    position: relative;
    pre {
      line-height: 21px;
      font-size: 12px;
      background: #000;
      max-height: 300px;
      color: #f2f3f6;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      white-space: pre;
      margin-right: 0;
      margin-bottom: 20px;
      padding: 15px;
      border-radius: 4px;
      text-align: left;
      overflow: scroll;
    }
    .actions {
      margin: 40px 0 20px;
    }
  }
  @media (min-width: 900px) {
    .text-wrapper {
      width: 45vw;
      margin: 10vh 0 0 10vw;
      max-height: 80vh;
    }
    .moon-man {
      width: 59.375%;
    }
  }
`;

const CodeWrapper = styled.pre`
  padding: 32px;
  max-width: 100%;
  scroll: auto;
  overflow: hidden;
`;

class FullPageError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      children,
      blueTitle,
      title,
      message,
      errorCode,
      theme
    } = this.props;
    return (
      <ErrorMessagePageWrapper
        style={{
          background: `${theme.navBG} url(${stars}) no-repeat center center`
        }}
      >
        <div className="header-wrapper">
          <Link className="logo" to={"/"}>
            <PlatformWordmark alt="logo" color="#ffffff" size={122} />
          </Link>
        </div>
        <div className="text-wrapper">
          {blueTitle ? (
            <Heading1>
              <span className="blue">Oops,</span>
              <br /> {title}
            </Heading1>
          ) : (
            <Heading1>{title}</Heading1>
          )}
          {message && <p>{message}</p>}
          {errorCode && <CodeWrapper>{errorCode}</CodeWrapper>}
          {children && children}
        </div>
        <div className="moon-man">
          <img src={sadMoonMan} alt="sad moon man" />
        </div>
      </ErrorMessagePageWrapper>
    );
  }
}

FullPageError.propTypes = {
  children: PropTypes.node,
  blueTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  errorCode: PropTypes.object,
  theme: PropTypes.object
};

export default withTheme(FullPageError);
