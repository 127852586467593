import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router";
import ReactDOM from "react-dom";

import ChevronIcon from "Components/icons/ChevronIcon";
import MenuDropdownWrapper from "Components/MenuDropdownWrapper";
import client from "Libs/platform";
import { redirectFromBeta } from "Libs/utils";

const Layout = styled.div`
  display: inline-block;
  position: relative;
  button {
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    background: transparent;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: inherit;
    padding: 0 35px 0 8px;
    white-space: nowrap;
    cursor: pointer;
    img {
      max-width: 24px;
      max-height: 24px;
      border-radius: 2px;
    }
    span.chevron {
      position: absolute;
      right: 3px;
      top: 6px;
      height: 24px;
      width: 24px;
    }
    &.picture {
      padding: 0 35px 0 3px;
    }
    &:hover,
    &:focus,
    &:active {
      background-color: rgba(255, 255, 255, 0.12);
      border-radius: 4px;
      outline: none;
    }
  }
  a {
    text-decoration: none;
    color: #38485e;
    font-size: 15px;
  }
  .menu-dropdown {
    padding: 8px;
    width: 214px;
    min-width: 214px;
    box-sizing: border-box;
    left: auto;
    right: 0;
    margin-top: 8px;
    a {
      &:hover,
      &:focus,
      &:active {
        background: ${props =>
          props.theme.name === "contrast"
            ? "transparent"
            : props.theme.menuHover};
        color: ${props =>
          props.theme.name === "contrast" ? props.theme.links : "#38485e"};
        border: ${props =>
          props.theme.name === "contrast" ? "none" : "inherit"};
        box-shadow: ${props =>
          props.theme.name === "contrast" ? "none" : "inherit"};
        margin: ${props => (props.theme.name === "contrast" ? "0" : "inherit")};
        position: relative;
        span.active {
          position: absolute;
          top: 14px;
          left: 4px;
          display: ${props =>
            props.theme.name === "contrast" ? "inline-block" : "none"};
          height: 4px;
          width: 4px;
          border-radius: 4px;
          background: ${props => props.theme.links};
        }
      }
    }
  }
`;

const BetaSwitch = styled.div`
  background: #f1f1f4;
  border-top: 1px solid #c9d0e4;
  margin: 8px -8px -8px;
  border-radius: 0 0 4px 4px;
  padding: 16px 24px;
  label {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    min-width: 214px;
    color: #38485e;
    font-weight: 600;
    cursor: pointer;
    font-size: 13px;
    cursor: pointer;
    .label-text {
      line-height: 24px;
      margin-bottom: 8px;
      display: inline-block;
    }
    input {
      opacity: 0;
    }
  }
  .toggle {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 32px;
    width: 100%;
    border-radius: 2px;
    background: #ffffff;
    padding: 4px;
    box-sizing: border-box;
    position: relative;
    .option {
      line-height: 23px;
      width: 78px;
      background: transparent;
      text-align: center;
      font-size: 13px;
      font-weight: 600;
      border-radius: 2px;
      &.active {
        background: ${props => props.theme.buttonBg};
        color: #fff;
        box-shadow: 0 1px 4px rgba(152, 160, 171, 0.6);
      }
    }
  }
`;

class UserMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      beta: true
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleBeta = this.toggleBeta.bind(this);
    this.getUserProfile = this.getUserProfile.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  componentWillReceiveProps() {
    const { userInfo } = this.props;
    const { profileLoaded } = this.state;
    if (userInfo && userInfo.uuid && !profileLoaded) {
      this.getUserProfile(userInfo.uuid);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  getUserProfile(userId) {
    this.setState({ profileLoaded: true }, async () => {
      const { new_ui } = await client.getUserProfile(userId);
      this.setState({ beta: new_ui });
    });
  }

  toggleMenu() {
    const isOpen = this.state.isOpen;
    this.setState({
      isOpen: !isOpen
    });
  }

  async toggleBeta() {
    const { new_ui } = await client.updateUserProfile(
      this.props.userInfo.uuid,
      {
        new_ui: !this.state.beta
      }
    );
    this.setState({ beta: new_ui });

    if (!this.state.beta) {
      redirectFromBeta(window.location.pathname, this.props.project);
    }
  }

  handleClickOutside(e) {
    if (!ReactDOM.findDOMNode(this).contains(e.target)) {
      this.setState({
        isOpen: false
      });
    }
  }
  render() {
    const { userInfo, accountUrl, docsUrl } = this.props;
    const { beta } = this.state;

    return (
      <Layout>
        <button
          onClick={this.toggleMenu}
          type="button"
          className={`user-menu-toggle ${this.state.isOpen ? " open" : ""}${
            userInfo.picture && !userInfo.picture.endsWith("user-default.png")
              ? " picture"
              : ""
          }`}
        >
          {userInfo.picture &&
          !userInfo.picture.endsWith("user-default.png") ? (
            <img src={userInfo.picture} alt={userInfo.display_name} />
          ) : (
            userInfo.display_name
          )}
          <ChevronIcon color="#ffffff" />
        </button>
        <MenuDropdownWrapper
          className="menu-dropdown"
          isOpen={this.state.isOpen}
        >
          <ul>
            <li>
              <Link className="menu-item" href={`${accountUrl}/user`}>
                <span className="active" />
                Account
              </Link>
            </li>
            <li>
              <Link className="menu-item" href={`${accountUrl}/user/orders`}>
                <span className="active" />
                Billing
              </Link>
            </li>
            <li>
              <Link className="menu-item" href={`${accountUrl}/support`}>
                <span className="active" />
                Support
              </Link>
            </li>
            <li>
              <Link className="menu-item" href={docsUrl}>
                <span className="active" />
                Help
              </Link>
            </li>
            <li>
              <Link className="menu-item" href={`${accountUrl}/user/logout`}>
                <span className="active" />
                Logout
              </Link>
            </li>
            <li>
              <BetaSwitch>
                <label
                  htmlFor="beta-switch"
                  className={`checkbox${this.state.beta ? " checked" : ""}`}
                >
                  <span className="label-text">Console mode</span>
                  <input
                    id="beta-switch"
                    type="checkbox"
                    name="beta-switch"
                    checked={beta}
                    onClick={this.toggleBeta}
                    aria-checked={beta}
                  />
                  <span className="toggle">
                    <span className={`option ${!beta ? " active" : ""}`}>
                      Legacy
                    </span>
                    <span className={`option ${beta ? " active" : ""}`}>
                      New
                    </span>
                  </span>
                </label>
              </BetaSwitch>
            </li>
          </ul>
        </MenuDropdownWrapper>
      </Layout>
    );
  }
}

UserMenu.propTypes = {
  userInfo: PropTypes.object,
  accountUrl: PropTypes.string,
  docsUrl: PropTypes.string,
  project: PropTypes.object
};

export default UserMenu;
