import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import { LiveAnnouncer, LiveMessage } from "react-aria-live";

import logger from "Libs/logger";

import SubNavBarContainer from "Containers/SubNavBarContainer";
import NavBar from "./NavBar";
import MaxWidthContainer from "Components/MaxWidthContainer";
import Main from "Components/Main";
import SkipToContentLink from "Components/SkipToContentLink";
import ErrorBoundary from "Components/ErrorBoundary";
import EnvironmentError from "Containers/EnvironmentError";
import ProjectError from "Containers/ProjectError";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.i = 0;
    this.state = {
      hasError: false,
      search: ""
    };
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    // document.onkeydown = e => {
    //   if (e.ctrlKey) {
    //     this.i++;
    //     if (this.i > 2) {
    //       this.i = 0;
    //     }
    //     this.props.loadTheme(this.i);
    //   }
    // };
    this.props.init();
    this.props.loadTheme(0);
    this.setState({
      search: ""
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname === "/") {
      this.onSearchChange();
    }
  }

  componentDidCatch(error, errorInfo) {
    logger(error, { extra: errorInfo });
    this.setState({
      hasError: true
    });
  }

  onSearchChange(event) {
    event
      ? this.setState({
          search: event.target.value
        })
      : this.setState({
          search: ""
        });
  }

  render() {
    if (!this.props.me || !this.props.theme) {
      return false;
    }
    return (
      <ErrorBoundary>
        <ThemeProvider theme={this.props.theme.toJS() || {}}>
          <LiveAnnouncer>
            <SkipToContentLink href="#main" tabIndex="0">
              Skip to main content
            </SkipToContentLink>
            <ProjectError />
            <EnvironmentError
              projectId={this.props.params.projectId}
              organizationId={this.props.params.organizationId}
            />
            {this.props.location.pathname.indexOf("/create-project") == -1 && (
              <NavBar
                push={this.props.router.push}
                projectId={this.props.params.projectId}
                organizationId={this.props.params.organizationId}
                environmentId={this.props.params.environmentId}
                role="navigation"
                onSearchChange={this.onSearchChange}
                currentPathName={this.props.location.pathname}
                user={this.props.me.toJS()}
              />
            )}

            {this.props.location.pathname.indexOf("/create-project") == -1 ? (
              <Main id="main" className="main" role="main">
                <MaxWidthContainer>
                  <SubNavBarContainer
                    projectId={this.props.params.projectId}
                    environmentId={this.props.params.environmentId}
                    organizationId={this.props.params.organizationId}
                    teamId={this.props.params.teamId}
                    currentPathName={this.props.location.pathname}
                    push={this.props.router.push}
                  />
                </MaxWidthContainer>
                <MaxWidthContainer>
                  {this.state.titleFilter ? (
                    <LiveMessage
                      message="project results have been filterd by search"
                      aria-live="polite"
                    />
                  ) : (
                    this.props.location.pathname === "/" && (
                      <LiveMessage
                        message="showing all available projects"
                        aria-live="polite"
                      />
                    )
                  )}
                  {React.cloneElement(this.props.children, {
                    search: this.state.search
                  })}
                </MaxWidthContainer>
              </Main>
            ) : (
              <div>
                <MaxWidthContainer>
                  {this.state.titleFilter ? (
                    <LiveMessage
                      message="project results have been filterd by search"
                      aria-live="polite"
                    />
                  ) : (
                    this.props.location.pathname === "/" && (
                      <LiveMessage
                        message="showing all available projects"
                        aria-live="polite"
                      />
                    )
                  )}
                  {React.cloneElement(this.props.children, {
                    search: this.state.search
                  })}
                </MaxWidthContainer>
              </div>
            )}
          </LiveAnnouncer>
        </ThemeProvider>
      </ErrorBoundary>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  init: () =>
    import("Reducers/app").then(appReducer => dispatch(appReducer.init())),
  loadTheme: i =>
    import("Reducers/app/theme").then(themeReducer =>
      dispatch(themeReducer.loadTheme(i))
    )
});

const mapStateToProps = state => ({
  me: state.app.get("me"),
  theme: state.theme.get("data")
});

App.propTypes = {
  children: PropTypes.node,
  init: PropTypes.func,
  loadTheme: PropTypes.func,
  params: PropTypes.shape({
    projectId: PropTypes.string,
    environmentId: PropTypes.string,
    organizationId: PropTypes.string,
    teamId: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  me: PropTypes.object,
  theme: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
