import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import AnimatedHeaderWithArrow from "./AnimatedHeaderWithArrow";
import MaxWidthContainer from "Components/MaxWidthContainer";

const Layout = styled.nav`
  padding: 0;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  & > div {
    margin: 0;
    &:first-child {
      margin-left: 0;
    }
  }
  .header-arrow {
    color: ${props => props.theme.navText};
    font-weight: normal;
    .breadcrumb-link {
      opacity: 0.7;
    }
    .active,
    .breadcrumb-link.environment:last-child {
      opacity: 1;
    }
    &:not(#environment-link),
    .environment span[role="button"] {
      line-height: 32px;
      padding: 0 6px;
      font-size: 12px;
      display: inline-block;
      &:hover,
      &:focus {
        background: rgba(255, 255, 255, 0.12);
        border-radius: 4px;
        color: ${props => props.theme.navText};
        outline: none;
      }
    }
    &:not(#environment-link):hover,
    &:not(#environment-link):focus,
    .environment span[role="button"]:hover,
    .environment span[role="button"]:focus {
    }
    &:not(#environment-link):active,
    .environment span[role="button"]:active {
      background: transparent;
      color: ${props => props.theme.navText};
    }
    &:first-child {
      padding-left: 8px;
    }
    &#environment-link {
      padding-left: 0;
    }
  }
  .breadcrumb-set {
    .breadcrumb-link {
      min-width: 74px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 32px;
      &.home {
        min-width: 48px;
      }
    }
    svg {
      transform: rotate(-90deg);
    }
    &:first-child {
      margin-left: -8px;
    }
    &:last-child {
      .chevron {
        display: none;
      }
    }
  }
  .chevron {
    margin: 0 -4px;
    width: 24px;
    display: flex;
    align-items: center;
    svg > g > g {
      fill: #fff;
    }
  }
  .path-separator {
    margin: 0px 4px 0 0;
  }
  .home {
    display: inline-block;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  @media (min-width: 768px) {
    flex-wrap: nowrap;
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -1px;
  }
`;

const Path = ({
  projectId,
  environmentId,
  organizationId,
  projectName,
  environmentPath = [],
  openEnvironment,
  goToHome,
  goToProject,
  isProjectOpen,
  isEnvironmentOpen,
  intl
}) => (
  <MaxWidthContainer>
    <Layout id="breadcrumbs" role="menubar">
      {projectId && (
        <React.Fragment>
          <AnimatedHeaderWithArrow
            onHeaderClick={goToHome}
            isOpen={false}
            animate={false}
            prefixId="home"
            hideArrow={!organizationId}
            className="breadcrumb-set"
          >
            <span id="home-navigation" className="breadcrumb-link home">
              {intl.formatMessage({ id: "projects" })}
            </span>
          </AnimatedHeaderWithArrow>
          <AnimatedHeaderWithArrow
            isOpen={isProjectOpen}
            onHeaderClick={goToProject}
            animate={false}
            prefixId="project"
            hideArrow={!environmentId}
            className="breadcrumb-set"
          >
            <span
              id="project-navigation"
              className={`breadcrumb-link project${
                environmentId ? "" : " active"
              }`}
            >
              {projectName
                ? projectName
                : intl.formatMessage({ id: "projects" })}
            </span>
          </AnimatedHeaderWithArrow>
        </React.Fragment>
      )}

      {environmentId && (
        <AnimatedHeaderWithArrow
          isOpen={isEnvironmentOpen}
          onArrowClick={openEnvironment}
          animate={false}
          prefixId="environment"
          hideArrow={true}
          className="breadcrumb-set"
        >
          <span id="environment-navigation">
            {environmentPath
              ? environmentPath.map((path, index) => (
                  <span key={index} className="breadcrumb-link environment">
                    {path}
                    {index !== environmentPath.length - 1 && (
                      <span className="path-separator"> {"/"}</span>
                    )}
                  </span>
                ))
              : intl.formatMessage({ id: "environments" })}
          </span>
        </AnimatedHeaderWithArrow>
      )}
    </Layout>
  </MaxWidthContainer>
);

Path.propTypes = {
  organizationName: PropTypes.string,
  projectName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  environmentPath: PropTypes.array,
  openOrganization: PropTypes.func,
  openProject: PropTypes.func,
  openEnvironment: PropTypes.func,
  isOrganizationOpen: PropTypes.bool,
  isProjectOpen: PropTypes.bool,
  isEnvironmentOpen: PropTypes.bool,
  projectId: PropTypes.string,
  environmentId: PropTypes.string,
  organizationId: PropTypes.string,
  goToHome: PropTypes.func,
  goToOrganization: PropTypes.func,
  goToProject: PropTypes.func,
  organizationMenu: PropTypes.node,
  projectMenu: PropTypes.node,
  intl: PropTypes.object
};

export default injectIntl(Path);
