import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import styled from "styled-components";

import FullPageError from "Components/FullPageError";
import ErrorBoundary from "Components/ErrorBoundary";
import Button from "Components/Button";

const Layout = styled.div`
  z-index: 10;
`;

class ProjectError extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }
  render() {
    const { project, error, isLoading, intl } = this.props;

    if (project || isLoading || !error) {
      return false;
    }
    const errorHasMessage = error && error._root && error._root.entries;
    let errorText = {};
    // Some errors come in as objects and some as strings with different labels.
    if (errorHasMessage && typeof error === "object") {
      error._root.entries.forEach(function(key) {
        errorText[key[0]] = key[1];
      });
    }
    if (typeof error === "string") {
      errorText = JSON.parse(error);
    }
    let errorTitle = intl.formatMessage({ id: "error_loading_project" });
    let errorMessage = intl.formatMessage({
      id: "error_loading_project_description"
    });

    if (errorText && (errorText.status || errorText.code)) {
      if (errorText.status === 403 || errorText.code === 403) {
        errorTitle = intl.formatMessage({ id: "error_403_title" });
        errorMessage = intl.formatMessage({ id: "error_project_403" });
      }
      if (errorText.status === 404 || errorText.code === 404) {
        errorTitle = intl.formatMessage({ id: "error_loading_project" });
        errorMessage = intl.formatMessage({ id: "error_project_404" });
      }
    }

    return (
      <Layout>
        <ErrorBoundary>
          <FullPageError title={errorTitle} message={errorMessage}>
            <div className="actions">
              <Button
                className="primary"
                onClick={() => (window.location = "/")}
              >
                Return to projects
              </Button>
            </div>
          </FullPageError>
        </ErrorBoundary>
      </Layout>
    );
  }
}

ProjectError.propTypes = {
  project: PropTypes.object,
  intl: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLoading: PropTypes.bool
};

const mapStateToProps = (state, props) => ({
  project:
    state.project &&
    state.project.getIn(["data", props.organizationId, props.projectId]),
  error: state.project && state.project.get("projectLoadingError"),
  isLoading: state.project && state.project.get("loading")
});

export default connect(mapStateToProps)(injectIntl(ProjectError));
