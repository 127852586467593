import styled from "styled-components";

export default styled.button`
  border: 1px solid ${props => props.theme.buttonBg};
  background-color: ${props => props.theme.buttonBg};
  color: ${props => props.theme.buttonText};
  padding: 0 8px;
  font: 0/0 none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 30px;
  box-sizing: border-box;
  text-transform: capitalize;
  box-shadow: none;
  cursor: pointer;
  border-radius: 2px;
  display: inline-flex;
  height: 32px;
  flew-wrap: nowrap;
  align-items: center;
  text-decoration: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */

  svg {
    height: auto;
    width: 16px;
    > g > g {
      fill: ${props => props.theme.buttonText};
    }
  }
  &:hover {
    background-color: ${props => props.theme.buttonHover};
    color: ${props => props.theme.buttonText};
    border-color: ${props => props.theme.buttonHover};
    svg > g > g {
      fill: ${props => props.theme.buttonText};
    }
  }
  &:focus {
    border-color: ${props => props.theme.buttonHover};
    box-shadow: 0 0 2px ${props => props.theme.buttonHover};
    outline: none;
    marign: -1px;
  }
  @media (min-width: 990px) {
    font: 14px/30px "Open Sans", Helvetica, Arial, sans-serif;
    padding: 0 16px;
    svg {
      margin-right: 8px;
    }
    &.inactive-message {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;
