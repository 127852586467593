import styled from "styled-components";

export default styled.header`
  width: 100%;
  max-width: 100%;
  min-height: 32px;
  box-sizing: border-box;
  padding: 0;
  font-weight: 300;
  min-height: 138px;
  z-index: 9;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
  .navigation-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 18px;
  }
  .float-box-wrapper {
    padding: 0 24px;
  }
  .float-box {
    background: ${props => props.theme.navBar};
    color: #fff;
    box-sizing: border-box;
    margin-top: -24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    box-shadow: 0 6px 24px rgba(75, 97, 128, 0.32);
    margin: 0 -24px;
    .navigation-menu {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      width: 100%;
      > nav,
      > .info-button {
        width: 50%;
      }
    }
    > .search-wrapper {
      font-weight: 400;
      margin-left: 24px;
      width: 574px;
      max-width: 100%;
      .search-image {
        position: absolute;
        left: 10px;
        top: 16px;
      }
      input.search {
        color: #fff;
        font-size: 14px;
        font-weight: 400 !important;
        padding-left: 42px;
        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
          font-weight: 400 !important;
        }
        &:focus {
          border: 1px solid ${props => props.theme.buttonHover};
          box-shadow: 0 0 2px ${props => props.theme.buttonHover};
          border-radius: 4px;
        }
      }
      &.search-active input.search:focus {
        border: none;
        box-shadow: none;
      }
      svg {
        opacity: 0.5;
        path,
        rect {
          fill: #fff;
        }
      }
      &:hover {
        .search-image {
          opacity: 1;
        }
        svg {
          opacity: 1;
          rect {
            fill: #fff;
          }
        }
        input.search::placeholder {
          color: #fff;
        }
      }
      &:focus-within {
        svg {
          opacity: 1;
        }
        &:after {
          display: none;
        }
      }
      &:after {
        display: none;
      }
    }
  }
  span.add-project {
    min-width: 144px;
    text-align: right;
    margin-right: 24px;
    white-space: nowrap;
  }
  .action-buttons {
    padding: 0 12px;
    position: absolute;
    bottom: -96px;
  }
  @media (max-width: 768px) {
    .user-widget__toggle,
    .user-widget__accounts_menu_toggle {
      line-height: 32px;
    }
    .user-widget__dropdown__menu,
    .user-widget__mega_menu {
      width: calc(100vw - 40px);
      position: absolute;
      left: 0;
      box-sizing: border-box;
    }
    .user-widget__free-trial {
      margin: 7px 0;
    }
  }
  @media (min-width: 768px) {
    position: fixed;
    .navigation-bar {
      align-items: center;
    }
    .float-box-wrapper {
      padding: 0 40px;
    }
    .float-box {
      height: 56px;
      margin: -34px 0 0;
      padding: 0 12px;
      .navigation-menu {
        width: auto;
        margin-left: -12px;
        > nav,
        > .info-button {
          width: 232px;
        }
      }
      > .search-wrapper {
        margin-left: 0;
      }
    }
    .action-buttons {
      padding: 0;
      position: relative;
      bottom: auto;
    }
    span.add-project {
      margin-right: 0;
    }
    .user-widget__mega_menu {
      width: calc(100vw - 80px);
    }
  }
`;
