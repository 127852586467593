import styled from "styled-components";

export default styled.main`
  padding: 0 24px;
  background: ${props => props.theme.background};
  color: ${props => props.theme.text};
  @media (min-width: 768px) {
    padding: 160px 40px 0;
  }
`;
