import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: row;
  line-height: 32px;
  padding-bottom: 20px;
  width: calc(100% - 184px);
  text-overflow: ellipsis;
  a {
    color: #fff;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 32px;
  }
  a.header-arrow,
  a.arrow-toggle {
    z-index: 3;
    font-size: 12px;
    font-weight: 500;
  }
  svg {
    > g > g {
      fill: #fff;
    }
  }
  .header-arrow-open span {
    svg {
      > g > g {
        fill: #fff;
      }
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
    padding-bottom: 0;
  }
`;
