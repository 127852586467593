import styled from "styled-components";

export default styled.div`
  max-height: 36px;
  display: inline-block;
  a {
    display: flex;
    align-items: center;
    line-height: 32px;
    height: 32px;
    &.logo {
      height: 36px;
      width: auto;
      display: inline-flex;
      justify-content: center;
      margin: -4px;
      padding: 4px;
      > div {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        svg {
          max-height: 100%;
          max-width: 100%;
        }
      }
      &:hover {
        background: rgba(255, 255, 255, 0.16);
        border-radius: 2px;
      }
      &:focus {
        margin: -5px;
        outline: none;
        border: 1px solid ${props => props.theme.buttonHover};
        box-shadow: 0 0 2px ${props => props.theme.buttonHover};
      }
    }
  }
`;
