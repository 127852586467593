import styled from "styled-components";

export default styled.div`
  background-color: ${props => props.theme.navBG};
  color: ${props => props.theme.navText};
  padding: 0 24px;
  @media (min-width: 768px) {
    min-height: 98px;
    padding: 0 40px;
  }
`;
