import React from "react";
import PropTypes from "prop-types";
import logger from "Libs/logger";

import userWidgetInit, {
  updateContext
} from "platformsh-user-widget/dist/without-psh-client";

class UserWidget extends React.Component {
  componentDidMount() {
    userWidgetInit(document.getElementById("user-widget"), {
      source: this.props.source,
      getQueryString: this.props.getQueryString,
      accountUrl: this.props.accountUrl,
      nameOrAvatar: this.props.nameOrAvatar,
      showProjectsMenu: this.props.showProjectsMenu,
      adminOnly: true
    });
  }

  componentWillReceiveProps(nextProps) {
    updateContext(nextProps);
  }
  componentDidCatch(error, info) {
    logger(error);
    logger(info);
  }

  render() {
    return <div id="user-widget" />;
  }
}

UserWidget.propTypes = {
  source: PropTypes.string,
  getQueryString: PropTypes.func,
  accountUrl: PropTypes.string,
  nameOrAvatar: PropTypes.bool,
  showProjectsMenu: PropTypes.bool
};

export default UserWidget;
