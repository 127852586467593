import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import Loading from "Components/Loading";

import SubNavBar from "Components/SubNavBar";
import TeamNavBar from "./TeamNavBar";
import { getSubscription } from "Libs/utils";

const LOAD_SUBSCRIPTION_STANDBY = "app/projects/load_subscription_standby";
// const LOAD_SUBSCRIPTION_START = "app/projects/load_subscription_start";
const LOAD_SUBSCRIPTION_SUCCESS = "app/projects/load_subscription_success";

const Layout = styled.div`
  > div {
    box-sizing: border-box;
    > div {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      > h1 {
        padding: 20px 0 30px;
        color: #fff;
      }
    }
  }
  .title-wrapper {
    min-height: 74px;
  }
  h1 {
    color: #fff;
  }
  .logo {
    height: 40px;
    width: auto;
    margin-right: 20px;
  }
`;

const getEnvironmentTabs = (organizationId, environmentId, projectId) => [
  {
    name: "overview",
    route: `/${organizationId}/${projectId}/${environmentId}`
  },
  /*{
    name: "services",
    route: `/${organizationId}/${projectId}/${environmentId}/services`
  },*/
  {
    name: "backups",
    route: `/${organizationId}/${projectId}/${environmentId}/backups`,
    permission: "#edit"
  },
  {
    name: "settings",
    route: `/${organizationId}/${projectId}/${environmentId}/settings`,
    regex: new RegExp(`\/${projectId}\/${environmentId}\/settings\/{0,1}.*`),
    permission: "#edit"
  }
];

const getProjectTabs = (organizationId, projectId, subscription) => [
  {
    name: "overview",
    route: `/${organizationId}/${projectId}`
  },
  // {
  //   name: "plan",
  //   route: `/${organizationId}/${projectId}/plan`,
  //   permission: "#subscription_change"
  // },
  {
    name: "settings",
    route: `/${organizationId}/${projectId}/settings`,
    regex: new RegExp(`\/${projectId}\/settings\/{0,1}.*`),
    permission: "#edit"
  },
  {
    name: "edit_plan",
    route:
      subscription && subscription._links && subscription._links.plan_uri.href,
    position: "right",
    type: "button",
    permission: "#subscription_change"
  }
];

const getAccountTabs = organizationId => {
  let tabsData = [
    {
      name: "settings",
      route: `/${organizationId}/settings`
    },
    {
      name: "connected_accounts",
      route: `/${organizationId}/settings/accounts`
    },
    {
      name: "security",
      route: `/${organizationId}/settings/security`
    },
    {
      name: "tokens",
      route: `/${organizationId}/settings/tokens`
    },
    {
      name: "ssh-keys",
      route: `/${organizationId}/settings/ssh-keys`
    }
  ];

  return tabsData.filter(tab => !tab.disabled);
};

const getBillingTabs = organizationId => {
  let tabsData = [
    {
      name: "plan",
      route: `/${organizationId}/billing`
    },
    {
      name: "details",
      route: `/${organizationId}/billing/details`
    },
    {
      name: "history",
      route: `/${organizationId}/billing/history`,
      disabled: process.env.VENDOR_URL
    }
  ];

  return tabsData.filter(tab => !tab.disabled);
};

const getOrganizationTabs = organizationId => {
  let tabsData = [
    {
      name: "projects",
      route: `/${organizationId}`
    },
    {
      name: "settings",
      route: `/${organizationId}/settings`
    },
    {
      name: "billing",
      route: `/${organizationId}/billing`,
      disabled: process.env.VENDOR_URL
    }
  ];

  return tabsData.filter(tab => !tab.disabled);
};

const getTeamTabs = (organizationId, teamId) => [
  {
    name: "overview",
    route: `/${organizationId}/teams/${teamId}`
  },
  {
    name: "settings",
    route: `/${organizationId}/teams/${teamId}/settings`
  }
];

class SubNavBarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionStatus: LOAD_SUBSCRIPTION_STANDBY,
      subscriptionData: {}
    };
  }

  componentDidMount() {
    this.getPageAndMenu(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { project } = nextProps;
    const { subscriptionData, subscriptionStatus } = this.state;
    const subscriptionShouldReload =
      project.getSubscriptionId &&
      (subscriptionStatus === LOAD_SUBSCRIPTION_STANDBY ||
        subscriptionStatus === LOAD_SUBSCRIPTION_SUCCESS) &&
      subscriptionData.id !== project.getSubscriptionId();

    if (subscriptionShouldReload) {
      getSubscription(project, this);
    }
    this.getPageAndMenu(nextProps);
  }

  getPageAndMenu(props) {
    const { projectId, environmentId, organizationId, teamId } = props;

    if (
      props.currentPathName.startsWith(
        `/${organizationId}/${projectId}/${encodeURIComponent(environmentId)}`
      )
    ) {
      this.setState({
        navBar: false,
        page: "environment"
      });
    } else if (
      props.currentPathName.startsWith(`/${organizationId}/teams/${teamId}`)
    ) {
      this.setState({
        navBar: (
          <TeamNavBar
            projectId={props.projectId}
            organizationId={props.organizationId}
            teamId={props.teamId}
          />
        ),
        page: "team"
      });
    } else if (
      props.currentPathName.startsWith(`/${organizationId}/${projectId}`)
    ) {
      this.setState({
        navBar: false,
        page: "project"
      });
    } else if (
      props.currentPathName.startsWith(`/${organizationId}/settings`)
    ) {
      this.setState({
        navBar: false,
        page: "account"
      });
    } else if (props.currentPathName.startsWith(`/${organizationId}/billing`)) {
      this.setState({
        navBar: false,
        page: "billing"
      });
    } else if (props.currentPathName.startsWith(`/${organizationId}`)) {
      this.setState({
        navBar: false,
        page: "organization"
      });
    } else if (props.currentPathName === "/") {
      this.setState({
        navBar: false,
        page: "projects"
      });
    } else if (props.currentPathName === "/projects") {
      this.setState({
        navbar: false,
        page: "projects"
      });
    } else if (props.currentPathName === "/organizations") {
      this.setState({
        navbar: false,
        page: "organizations"
      });
    } else if (props.currentPathName === "/organizations/new") {
      this.setState({
        navbar: false,
        page: "organizations-new"
      });
    } else if (props.currentPathName === "/setup") {
      this.setState({
        navbar: false,
        page: "setup"
      });
    }
  }

  getSubNavBarProps() {
    const { projectId, environmentId, organizationId, teamId } = this.props;
    const { page } = this.state;

    if (page === "environment") {
      return {
        title: this.props.environment.title,
        tabs: getEnvironmentTabs(
          organizationId,
          encodeURIComponent(environmentId),
          projectId
        )
      };
    } else if (page === "project") {
      return {
        title: <Loading />,
        tabs: getProjectTabs(
          organizationId,
          projectId,
          this.state.subscriptionData
        )
      };
    } else if (page === "account") {
      return {
        title: "Account",
        tabs: getAccountTabs(organizationId)
      };
    } else if (page === "billing") {
      return {
        title: "Billing",
        tabs: getBillingTabs(organizationId)
      };
    } else if (page === "organization") {
      return {
        title: this.props.organizationId,
        tabs: getOrganizationTabs(organizationId)
      };
    } else if (page === "team") {
      return {
        title: this.props.teamId,
        tabs: getTeamTabs(organizationId, teamId)
      };
    } else if (page === "dashboard") {
      return {
        title: this.props.intl.formatMessage({ id: "dashboard" }),
        tabs: []
      };
    } else if (page === "projects") {
      return {
        title: this.props.intl.formatMessage({ id: "projects" }),
        tabs: []
      };
    } else if (page === "organizations") {
      return {
        title: this.props.intl.formatMessage({ id: "organization" }),
        tabs: []
      };
    } else if (page === "organizations-new") {
      return {
        title: this.props.intl.formatMessage({ id: "create_new_organization" }),
        tabs: []
      };
    } else if (this.props.currentPathName === "/setup") {
      return {
        title: this.props.intl.formatMessage({ id: "create_new_project" }),
        tabs: []
      };
    }

    return { title: " ", tabs: [] };
  }

  render() {
    let links;
    this.props.project && this.props.project.data
      ? (links = this.props.project.data._links)
      : "";
    this.props.environment && this.props.environment.data
      ? (links = this.props.environment.data._links)
      : "";

    return (
      <Layout>
        <SubNavBar
          {...this.getSubNavBarProps()}
          push={this.props.push}
          currentPathName={this.props.currentPathName}
          links={links}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state, props) => ({
  project:
    state.project &&
    state.project.getIn(
      ["data", props.organizationId, props.projectId],
      new Map()
    ),
  environment:
    state.environment &&
    state.environment.getIn(
      ["data", props.organizationId, props.projectId, props.environmentId],
      {}
    )
});

SubNavBarContainer.propTypes = {
  project: PropTypes.object,
  environment: PropTypes.object,
  projectId: PropTypes.string,
  teamId: PropTypes.string,
  organizationId: PropTypes.string,
  environmentId: PropTypes.string,
  currentPathName: PropTypes.string.isRequired,
  push: PropTypes.func,
  intl: PropTypes.object
};

export default connect(mapStateToProps)(injectIntl(SubNavBarContainer));
