import React from "react";
import styled from "styled-components";

import ButtonLink from "Components/ButtonLink";
import MaxWidthContainer from "Components/MaxWidthContainer";

const Wrapper = styled.div`
  background: ${props => props.theme.navActive};
  color: #f5f7fa;
  font-size: 14px;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    min-height: 40px;
  }
  .text {
    display: none;
  }
  .button {
    height: 24px;
    line-height: 24px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.8px;
    padding: 0 16px;
    &:hover,
    &:focus,
    &:active {
      line-height: 24px;
    }
  }
  .link-button {
    margin: 0 16px;
  }
  @media (min-width: 768px) {
    .text {
      display: inline;
    }
    .mobile-text {
      display: none;
    }
  }
`;

class HeaderAlert extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Wrapper className="header-alert">
        <MaxWidthContainer>
          <div className="message">
            <span className="text">
              New console is live, feedback welcome! Find us on
            </span>
            <span className="mobile-text">Feedback welcome on</span>
            <ButtonLink
              to="https://chat.platform.sh"
              text="slack"
              external={true}
            />
            or
            <ButtonLink
              to="https://accounts.platform.sh/support"
              text="Support"
              external={true}
            />
          </div>
        </MaxWidthContainer>
      </Wrapper>
    );
  }
}

export default HeaderAlert;
